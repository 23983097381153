@import '../../../styles/customMediaQueries.css';

.grid {
  display: block;
  color: white;
  background-color: var(--marketplaceColor);
  /* justify-content: space-evenly; */
  min-height: 450px;
  max-height: 600px;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.aspectWrapper {
  padding-bottom: 0;

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.image {
  height: 100%;
  width: 50%;
  display: inherit;
  object-fit: cover;

  @media (max-width: 768px) {
    display: none;
  }
}

.imageMobile {
  height: 100%;
  width: 50%;
  display: none;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
    display: inherit;
  }
}

.logo {
  /* Layout - image will take space defined by aspect ratio wrapper */
  width: 300px;
  height: 100px;
  object-fit: contain;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.title {
  @apply --marketplaceH1FontStyles;
  color: white;
  margin-top: 0;
}

.description {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
}

.field {
  border: 1.5px solid white;
  border-radius: 10px;
  color: white;
  /* padding-left: 10px; */
  width: 100%;
  max-width: 500px;
  flex: 1;
  ::placeholder {
    color: whitesmoke;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.submit {
  border: none;
}

.submit:hover {
  cursor: pointer;
}

.sendIcon {
  color: white;
}

.button {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 200px;
  max-height: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'hernandezbros' !important;
  margin-top: 0px;
  background-color: white;
  color: var(--marketplaceColor);

  &:hover {
    background-color: white;
  }
}

.mainTitle {
  font-family: 'inter';
  margin: 0;
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: 100;
  padding-right: 10px;
  margin-bottom: 15px;
  line-height: 30px;
}

.text {
  font-family: 'inter';
  margin: 0;
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 200;
  padding-right: 10px;
  margin-bottom: 30px;
}

.root {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 45vh;

  @media (max-width: 768px) {
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.wrapper {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding-right: 60px;

  @media (max-width: 768px) {
    padding-right: 10px;
  }
}

.textSection {
  width: 50%;
  height: 100%;
  background-color: var(--marketplaceColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;

  @media (max-width: 768px) {
    width: 100%;
  }
}
