.banner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.text {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  transition: top 0.5s ease-in-out;
  color: white;
  font-weight: 300;
}

.visible {
  top: 8px;

  @media (max-width: 768px) {
    top: 10%;
  }
}
