@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 45vh;

  @media (max-width: 768px) {
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.wrapper {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding-right: 60px;

  @media (max-width: 768px) {
    padding-right: 10px;
  }
}

.mainTitle {
  font-family: 'inter';
  margin: 0;
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: 100;
  padding-right: 10px;
  margin-bottom: 15px;
}

.text1 {
  font-family: 'inter';
  margin: 0;
  color: white;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  padding-right: 10px;
  margin-bottom: 15px;
  line-height: 25px; /* increase the line height */
}

.text2 {
  font-family: 'inter';
  margin: 0;
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 200;
  padding-right: 10px;
  margin-bottom: 15px;
  line-height: 25px;
  line-height-step: 20px;
}

.learnMoreButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 200px;
  max-height: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'hernandezbros';
  margin-top: 20px;
  background-color: white;
  color: var(--marketplaceColor);

  &:hover {
    background-color: white;
  }
}

.mainTitle {
  font-family: 'inter';
  margin: 0;
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: 100;
  padding-right: 10px;
  margin-bottom: 15px;
  line-height: 30px;
}

.textSection {
  width: 50%;
  height: 100%;
  background-color: var(--marketplaceColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.image {
  height: 100%;
  width: 50%;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
  }
}
