.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f7f9fa;
  padding: 40px 0;
  @media (max-width: 768px) {
    display: unset;
  }
}

.title {
  margin: 0;
  text-transform: uppercase;
  font-family: 'hernandezbros';
  font-size: 25px;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'hernandezbros';
    margin-bottom: 40px;
    margin-top: 20px;
  }
}

.logosWrapper {
  margin: 20px 0 60px 0;
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.logo {
  width: 15%;
  @media (max-width: 768px) {
    width: 100%;
    height: 10vh;
    margin-bottom: 40px;
  }
  & img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    &:hover {
      transform: scale(1.1);
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 10vh;
      margin-bottom: 40px;
    }
  }
}
