.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  padding-top: 40px;

  /* background-color: #f7f9fa; */
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.listingCard {
  width: 23%;
  margin-bottom: 20px;

  @media (max-width: 1050px) {
    width: 30%;
  }

  @media (max-width: 768px) {
    width: 45%;
  }
}

.mainTitle {
  padding: 20px 20px;
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  font-family: 'hernandezbros';
  /* text-transform: uppercase; */
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
}

.seeAllButton {
  font-family: 'lato';
  text-transform: uppercase;
  border: 1px solid var(--marketplaceColor);
  border-radius: var(--borderRadius);
  padding: 5px 15px;
  font-size: 16px;
  max-width: 150px;
  background-color: var(--marketplaceColor);
  color: white;
  text-align: center;
  &:hover {
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
  }
}
